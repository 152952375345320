@font-face {
  font-family: "Candara";
  src: url("./Candara.ttf");
}
* {
  box-sizing: border-box;
}
.w-60 {
  width: 52%;
}
.w-30 {
  width: 30%;

}
html,
body {
  font-family: var(--ccft-ts-primary);
  margin: 0;
  background-color: #d3cab7 !important;
}
#root {
  display: block;
  margin: 0 auto;
  position: relative;
  max-width: 1200px;
}

.layout {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.layout.nav--active main {
  opacity: .2;
  user-select: none;
}

.layout__backdrop,
.layout__frontdrop {
  background: linear-gradient(90deg, rgba(255,255,255,0.6811318277310925) 0%, rgba(253,29,29,0) 50%, rgba(47,47,4,0.7399553571428572) 91%);
  content: '';
  display: flex;
  height: 60%;
  width: 75%;
  @media screen and (min-width: 1400px) {
    height: 53%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    height: 30%;
  }
  
  right: 0;
  position: absolute;
  transform-origin: 0% 50%;
  align-items: center;
  z-index: 1;
}
.center-loader {
  display: block;
  margin: 0 auto;
  width: 100px;
}
.slide {
  opacity: 1!important;
  visibility: visible!important;
}
.supportingAc {
  img:nth-of-type(3) {
    margin-left: 20%;
    height: 200px;
    margin-right: 10px;
  }
  img:nth-of-type(4) {
    height: 200px;
  }
  p > img {
    margin: 0 auto;
    display: block;
  }
}

.layout.nav--active .layout__frontdrop {
  background-color: var(--ccft-cs-primary-alt);
  visibility: visible;
}

.layout__frontdrop {
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.layout__wrapper {
  display: grid;
  grid-template-columns: 300px repeat(3, 1fr);
  margin: auto;
  height: 100%;
  background-color: #d3cab785;
}

.layout__main {
  transition: opacity .5s;
  z-index: 1;
}

.hero {
  padding-top: 10rem;
}

.hero-title {
  color: #fff;
  font-family: var(--ccft-ts-secondary);
  font-weight: 400;
  font-size: 4.75rem;
  line-height: 1.2;
  margin-left: auto;
  margin-right: 5rem;
  margin-bottom: 0;
  position: relative;
  width: 32.5rem;
  z-index: 1;
}

.hero-title em {
  display: block;
  font-size: 2rem;
  font-style: normal;
  line-height: 1.2;
  transform: translateX(2.5rem);
}

.hero-text {
  background-color: rgba(0, 0, 0, .3);
  color: #fff;
  font-size: .92rem;
  line-height: 1.75;
  margin: 0;
  margin-left: auto;
  margin-right: 6rem;
  padding: 5rem 3rem 3rem;
  transform: translateY(-3rem);
  width: 50%;
  z-index: 0;
}

.hero-text a {
  box-shadow: 0 4px #fff;
  color: #fff;
  text-decoration: none;
}

.hero-block--content > *:last-child {
  margin-bottom: 0;
}

.layout__main {
  display: flex;
  flex-direction: column;
}

.layout__header {
  height: 100%;
  z-index: 2;
}

.layout__header nav {
  height: 100%;
}

.layout__main {
  grid-column: span 3;
}

.nav-left {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav--header-1 {
  counter-reset: n;
  height: 100%;
  padding: 0 2rem;
  position: relative;
}

.nav--header-1 > .nav__item:not(.nav__item--home) {
  counter-increment: n;
}

.nav--header-1 > .nav__item:not(.nav__item--home) > .nav__link {
  color: #6f694b;
  display: block;
  font-weight: 500;
  font-family: 'Oswald', sans-serif;
  font-size: 1.1rem;
  line-height: 1.1;
  letter-spacing: .045em;
  padding: 0.8rem 0rem;
  padding-left: 1rem;
  padding-top: 10px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  transition: transform .2s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  z-index: 1;
  @media screen and (min-width: 1400px) {
    padding: 0.8rem 0rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 0.9rem;
  }
}
.maximg {
  .document-container > img:nth-of-type(1), .document-container > img:nth-of-type(2) {
    width: 100%!important;
    max-width: 100%!important;
    max-height: 100%!important;
    height: 100%!important;
  }
  img, p > img {
    max-width: 30%!important;
    width: 30%!important;
  }
}
.nav--header-1 > .nav__item > .nav__link::before {
  content: counter(n, decimal-leading-zero);
  display: block;
  font-family: var(--ccft-ts-primary);
  font-size: 2rem;
  font-weight: bold;
  left: 0;
  top: 10px;
  @media screen and (max-width: 1300px) {
    top: -6px;
  }
  @media screen and (min-width: 1400px) {
    top: 0px;
  }
  opacity: .25;
  position: absolute;
  transition: transform .2s;
  z-index: -1;
}
.makeStyles-itemWrapper-3 {
  height: 750px!important;
}
.nav--header-1 > .nav__item > .nav__link:hover,
.nav--header-1 > .nav__item.nav__item--active > .nav__link {
  color: var(--ccft-cs-primary);
  transform: translateX(.5rem);
}

.nav--header-1 > .nav__item.nav__item--active .nav--header-2 {
  visibility: visible;
}

.nav--header-1 .nav__item--home {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.145 8.633l-2.145-8.633-2.148 8.636c-.572.366-1.034.877-1.358 1.477l-8.494 1.887 8.494 1.887c.324.6.786 1.111 1.358 1.477l2.148 8.636 2.157-8.64c.571-.367 1.03-.879 1.353-1.479l8.49-1.881-8.492-1.884c-.324-.603-.788-1.116-1.363-1.483zm-2.145 5.367c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2zm7 5l-3.43-2.186c.474-.352.893-.771 1.245-1.245l2.185 3.431zm-3.521-11.882l3.521-2.117-2.119 3.519c-.386-.542-.86-1.015-1.402-1.402zm-6.955 9.767l-3.524 2.115 2.118-3.521c.387.543.862 1.018 1.406 1.406zm-1.34-8.453l-2.184-3.431 3.431 2.184c-.474.352-.894.772-1.247 1.247z" fill="rgb(255, 255, 255)" style="transform-origin: 50% 50%; transform: rotateZ(-27deg);"/></svg>');
  background-position: 0px 50%;
  background-repeat: no-repeat;
  background-size: var(--ccft-brand-size) var(--ccft-brand-size);
  margin-bottom: 5px;
  padding: 0;
}

.nav--header-1 .nav__item--home > .nav__link {
  display: block; /* Necessary for text-indent to work */
  height: calc(var(--ccft-brand-size) + 40px);
  text-indent: -999px;
}

.bottomTitle {
  width: 20%;
   right: 1%; 
   bottom: 5%;
   @media screen and (max-width: 500px) {
     width: 55%;
     bottom: 42%;
     right: 5%;
   }
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.buttonsSection {
  bottom: 3%; 
  left: 20%; 
  width: 55%;
  @media screen and (max-width: 500px) {
    width: 100%;
    left: 0;
    bottom: 17%;
  }
}
.ac {
  width: 50%;
  left: 15%;
  bottom: 150px;
  @media screen and (min-width: 1400px) {
    width: 70%;
    bottom: 200px;
  }
  @media screen and (max-width: 500px) {
    width: 30%;
    bottom: 100px;
  }
}
.bottomContainer {
  position: absolute;
  bottom: 10px;
  max-width: 80%;
}
#circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: red;
}
.blazon {
  width: 35%;
  @media screen and (min-width: 1400px) {
    width: 70%;
  }
  @media screen and (max-width: 500px) {
    width: 30%;
  }
}
.mr-2 {
  margin-right: 10px;
}
.nav--header-2 {
  height: 100%;
  left: 270px;
  opacity: 0;
  padding: 2.5rem 5rem;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
}

.nav--header-2::before {
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" fill="rgb(255, 255, 255)"/></svg>');
  background-repeat: no-repeat;
  background-size: 30px 30px;
  content: '';
  cursor: pointer;
  display: block;
  margin-bottom: 6rem;
  height: 30px;
  width: 30px;
}

.nav--header-2 > .nav__item {
  margin-bottom: 2.75rem;
}

.nav--header-2 > .nav__item > .nav__link {
  background-image: linear-gradient(to bottom, var(--ccft-cs-primary) 0%, var(--ccft-cs-primary) 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 6px 6px;
  color: #fff;
  font-family: var(--ccft-ts-primary);
  font-size: 2.75rem;
  font-weight: bold;
  letter-spacing: .05em;
  text-decoration: none;
  transition: background-size .25s, color .3s;
}

.nav--header-2 > .nav__item > .nav__link:hover {
  background-size: 6px 50px;
  color: #000000;
}
.container {
  height: 100vh;
}
.smallTxt {
  font-size: 12px!important;
}
.bottomText {
  position: absolute;
  bottom: 50px;
  right: 100px;
  width: 12rem;
}

.mgrpdf__wrapper {
  canvas {
    max-height: 100vh !important;
    max-width: 100% !important;
    height: auto!important;
    width: auto!important;
  }
}
.modal-header {
  height: 30px;
  .close {
    margin: 0!important;
    padding: 0.3rem!important;
    position: absolute;
    right: 10px;
  }
}
button.close {
  background: transparent;
    border: none;
    font-size: 25px;
    .sr-only {
      display: none;
    }
}
.modal-body {
  width: 100%;
  img {
    max-height: 80vh;
  }
}
.min-80 {
  min-height: 80vh;
  @media (max-width: 500px) {
    min-height: auto;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1060px !important;
  }
  .modal-lg,
  .modal-xl {
    max-width: 1200px !important;
  }

  .carousel-item {
    margin: 0 auto;
  }
  .mosaicGallery {
    height: 80vh !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px !important;
  }
  .modal-header {
    padding: 0.2rem !important;
    align-items: center !important;
  }
}

.carousel-item {
  transition: all 3.2s ease-in-out !important;
}

.mgrpdf-navigation__controls--wrapper {
  width: 35% !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
}

.modal-content {
  max-width: 100%!important;
}

.goldBackground .modal-content {
  background-image: url("./gold_background.jpg");
  background-position: center !important;
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.gridGallery.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  .column {
    flex: 10%;
    max-width: 20%;
    padding: 0 4px;
  }

  .column {
    img {
      vertical-align: middle;
      width: 100%;
    }
  }
}
.rotate {
  canvas{
    transform: rotate(90deg);
  }
}
.imgRight {
  p:last-of-type {
    text-align: right!important;

    img {
      display: inline!important;
      width: 20%!important;
    }
  }
 
}
.rotateImg {
  p:nth-of-type(15) ~ p:not(:last-of-type){
   img {
    transform: rotate(90deg);
    margin: 80px auto
  }
}
}
.imgCenter {
  img {
    margin: 0 auto;
    display: block;
  }
}
.justify {
  text-align: justify;
}
.coverImage {
  background-image:  url("./fundal_thumbnail.jpg");
  background-position: top;
  background-size: 45% 100%;
  position: absolute;
  width: 80%;
  height: 60%;
  @media screen and (min-width: 1400px) {
    height: 53%;
  }
  @media screen and (min-width: 1200px) {
    max-width: 900px!important;
  }
  @media screen and (max-width: 900px) {
    max-width: 675px!important;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    height: 30%;
  }
  max-width: 750px!important;
  right: 0;
}
.w-90 {
  width: 90%;
}
.makeStyles-root-1 {
  background-color: transparent !important;
  max-width: 100%!important;
}
.centerImages {
  img {
    margin: 0 auto;
    display: block;
  }
}
.pg-viewer-wrapper img {
  width: auto!important;
  max-width: 70%;
}

.pg-viewer-wrapper img:nth-of-type(4) {
  transform: rotate(90deg);
  margin: 100px;
}

.Candara {
  font-family: "Oswald", sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1;
  color: black;
  margin-bottom: 0;
  &.link {
    cursor: pointer;
    @media screen and (max-width: 500px) {
      font-size: 0.5rem;
    }
  }
  &.black {
    color: black;
  }
}

.buttonLink {
  border: 2px solid #6f694b;
  border-radius: 15px;
  padding: 0.5em 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 2px solid white;
    background-color: #0000000d;
  }
  &.active {
    border: 2px solid white;
    background-color: #0000000d;
  }
}