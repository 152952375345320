.backgroundPuzzle {
    background-image:  url("../../fundal.jpg");
    background-position: center;
    background-size: cover;
    height: 100%;
}

.hoverBig {
    transition: transform .2s;
    &:hover {
        transform: scale(2.5);
        position: relative;
        z-index: 10;
    }
}
.image{
    filter: blur(20px);
    transition: 2s -webkit-filter ease;
    transition: opacity 400ms ease 0ms;
    &.full {
        filter: blur(0)
    }
}