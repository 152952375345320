input[type="radio"] {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

label {
  margin: 10px 0;
  cursor: pointer;
}

.slider {
  width: 100%;
  max-height: 80vh;
  height: 80vh;
  overflow: hidden;
  position: relative;
}
.slidePortret {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all ease-in-out 1.3s;
  opacity: 0;
  visibility: hidden;
}

input[type="radio"]:checked + div {
  opacity: 1;
  visibility: visible;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 3px;
  user-select: none;
  background-color: rgba(0,0,0,0.3);
  text-decoration: none;
  &:hover {
    color: white;
  }
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px;
}
