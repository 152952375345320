
.mgrpdf-navigation__controls--next, .mgrpdf-navigation__controls--previous {
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    width: 50px;
    height: 50px;
    display: flex!important;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
    a {
        font-size: 20px;
    }
}
.mgrpdf-navigation__controls--next {
    right: 20px;
}
.mgrpdf-navigation__controls--previous {
    left: 20px;
}
